import React from "react"
import { Upload, Row, Col, Form, InputNumber, Select, Button } from "antd"
import { InboxOutlined } from "@ant-design/icons"

import Compressor from "compressorjs"

import Layout from "../../layouts"
import { convertSpace } from "../../utils/helper"

import "./compress-picture.css"

const { Dragger } = Upload
const { Option } = Select
const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
}

class ToolList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: {
        name: "暂无",
        type: "暂无",
        size: "暂无",
        url: "https://files.mdnice.com/logo.png",
        lastModified: "暂无",
      },
      output: {
        name: "暂无",
        type: "暂无",
        size: "暂无",
        url: "https://files.mdnice.com/logo.png",
        lastModified: "暂无",
        ratio: "暂无",
      },
      width: undefined,
      height: undefined,
      quality: 0.6,
      file: undefined,
    }
  }

  compressImg = (file, width, height, quality) => {
    new Compressor(file, {
      quality: quality,
      width: width,
      height: height,
      convertSize: 1000000,
      success: compressFile => {
        const url = URL.createObjectURL(compressFile)
        const { name, size, type, lastModified } = compressFile
        const ratio =
          (
            (this.state.file.size - compressFile.size) /
            this.state.file.size
          ).toFixed(4) *
            100 +
          "%"
        this.setState({
          output: {
            name,
            size: convertSpace(size),
            type,
            url,
            lastModified,
            ratio,
          },
        })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  customRequest = ({ file }) => {
    const { width, height, quality } = this.state
    const { name, size, type, lastModified } = file
    const url = URL.createObjectURL(file)
    this.setState({
      input: {
        name,
        size: convertSpace(size),
        type,
        url,
        lastModified,
      },
      file,
    })
    this.compressImg(file, width, height, quality)
  }

  handleWidth = value => {
    const { height, quality, file } = this.state
    this.setState({ width: value })
    this.compressImg(file, value, height, quality)
  }

  handleHeight = value => {
    const { width, quality, file } = this.state
    this.setState({ height: value })
    this.compressImg(file, width, value, quality)
  }

  handleQuality = value => {
    const { width, height, file } = this.state
    this.setState({ quality: value })
    this.compressImg(file, width, height, value)
  }

  render() {
    return (
      <Layout isSpacerDisplay={true} title="图片压缩">
        <div className="mdnice-compress-picture-container">
          <div className="mdnice-compress-picture-block">
            <Col>
              <Dragger
                customRequest={this.customRequest}
                showUploadList={false}
                accept="image/*"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或者拖拽图片进行压缩</p>
              </Dragger>
              <Row className="mdnice-compress-picture-main">
                <Col sm={24} lg={8} className="mdnice-compress-picture-options">
                  <h2 className="mdnice-compress-picture-title">配置项</h2>
                  <Form {...layout} name="basic">
                    <Form.Item label="宽度" name="width">
                      <InputNumber
                        min={2}
                        onChange={this.handleWidth}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item label="高度" name="height">
                      <InputNumber
                        min={2}
                        onChange={this.handleHeight}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item label="质量" name="quality">
                      <Select
                        defaultValue={this.state.quality}
                        onChange={this.handleQuality}
                      >
                        <Option value={0}>0</Option>
                        <Option value={0.1}>0.1</Option>
                        <Option value={0.2}>0.2</Option>
                        <Option value={0.3}>0.3</Option>
                        <Option value={0.4}>0.4</Option>
                        <Option value={0.5}>0.5</Option>
                        <Option value={0.6}>0.6</Option>
                        <Option value={0.7}>0.7</Option>
                        <Option value={0.8}>0.8</Option>
                        <Option value={0.9}>0.9</Option>
                        <Option value={1}>1</Option>
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
                <Col sm={24} lg={16} className="mdnice-compress-picture-inout">
                  <Row className="mdnice-compress-picture-input">
                    <Col lg={24}>
                      <Row>
                        <h2 className="mdnice-compress-picture-title">输入</h2>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <img src={this.state.input.url} />
                        </Col>
                        <Col offset={2} lg={16}>
                          <Row className="mdnice-compress-picture-row">
                            <strong>文件名：</strong>
                            <p>{this.state.input.name}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>修改时间：</strong>
                            <p>{this.state.input.lastModified}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>类型：</strong>
                            <p>{this.state.input.type}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>大小：</strong>
                            <p>{this.state.input.size}</p>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mdnice-compress-picture-output">
                    <Col lg={24}>
                      <Row>
                        <h2 className="mdnice-compress-picture-title">输出</h2>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <img
                            src={this.state.output.url}
                            style={{ marginBottom: "20px" }}
                          />
                        </Col>
                        <Col offset={2} lg={16}>
                          <Row className="mdnice-compress-picture-row">
                            <strong>文件名：</strong>
                            <p>{this.state.output.name}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>修改时间：</strong>
                            <p>{this.state.output.lastModified}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>类型：</strong>
                            <p>{this.state.output.type}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>大小：</strong>
                            <p>{this.state.output.size}</p>
                          </Row>
                          <Row className="mdnice-compress-picture-row">
                            <strong>压缩率：</strong>
                            <p>{this.state.output.ratio}</p>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Button
                          download={this.state.output.name}
                          href={this.state.output.url}
                          type="primary"
                        >
                          下载
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ToolList
